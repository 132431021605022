@import url(https://fonts.googleapis.com/css?family=PT+Serif:400,700,400italic,700italic|PT+Sans:400,400italic,700,700italic|PT+Sans+Caption:400,700|Alegreya+SC:400,500&display=swap);

$body-bg: #fbfbf1;
$body-color: #000;
$link-hover-color: #000;

$darkGreen: #663;
$midGreen: #996;
$lightGreen: #ccccb3;
$washedGreen: #f0f0e2;
$redHighlight: #a94442;

$brand-primary: $darkGreen;
$link-color: $darkGreen;
$text-muted: $midGreen;

$input-bg: #fff;
$input-border-color: $lightGreen;

$borderColour: $brand-primary;

$border-width: 2px;

//$enable-gradients: true;
$grid-gutter-width: 1rem;

$list-group-bg: $washedGreen;
$list-group-border-color: $lightGreen;
$list-group-hover-bg: $lightGreen;

$card-spacer-x: 1rem;
$card-border-color: $lightGreen;
$card-bg: $washedGreen;
$card-cap-bg: $washedGreen;

$font-family-base: "PT Serif", Palatino, Georgia, serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";

$component-active-bg: $darkGreen;

$carousel-control-color: $redHighlight;
$carousel-control-opacity: 1;

// Buttons
$btn-font-weight: bold;

$custom-colors: (
  "primary": $redHighlight,
  "secondary": $midGreen,
);

// Tables
$table-border-color: $lightGreen;

$navbar-default-toggle-icon-bar-bg: #f7f7e3;
$navbar-default-toggle-border-color: #f7f7e3;
$navbar-default-toggle-hover-bg: #663;
$navbar-default-color: #000;
$navbar-default-bg: #996;
$navbar-default-border: #663;
$navbar-default-link-color: #f7f7e3;
$navbar-default-link-active-color: #f7f7e3;
$navbar-default-link-active-bg: #663;
$navbar-default-brand-color: #f7f7e3;
$navbar-default-brand-hover-color: #f7f7e3;

@import "./bootstrap-custom";

body {
  position: relative;
  padding: 0 0.5rem;
  @include media-breakpoint-up(sm) {
    padding: 0 1rem;
  }
}

.table thead th {
  background-color: $washedGreen;
}

.content.hero {
  @include border-radius($card-border-radius);
  background-color: $washedGreen;
  background-position: 50%;
  background-size: cover;
  border: $border-width solid $lightGreen;
  padding: 1rem;
}

.navbar-brand-hidden .navbar-collapse {
  padding-left: 0;
}

footer.page-footer {
  border-top: solid $border-width $redHighlight;
  font-family: "PT Sans Caption", sans-serif;
  font-size: 0.75rem;
  margin-top: 1rem;
  padding: 0.75rem 0;
}

.content {
  padding: 1rem 0;
  margin-bottom: 1rem;
}

.card-header {
  h3 {
    margin: 0;
    padding: 0;
    font-size: 1.25rem;
  }
}

.card img {
  background-color: #fff;
}

a.list-group-item {
  font-family: "PT Sans Caption", "PT Sans", sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  color: #663;
}

select,
textarea,
input {
  font-family: "PT Sans", sans-serif;
}

.btn {
  font-family: "PT Sans Caption", sans-serif;
}

.btn-outline-secondary {
  color: $darkGreen;
  &:link,
  &:visited {
    color: $darkGreen;
  }
}

#brand {
  text-align: center;
  h1 {
    @include font-size(3rem);
    border-bottom: solid 3px $borderColour;
    padding: 1rem 0;
    margin: 0;
    font-weight: normal;
    text-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px;
    font-family: "Alegreya SC", serif;
    a:link,
    a:visited {
      color: #000;
      text-decoration: none;
    }
    a:hover {
      text-decoration: none;
    }
  }
}

.nav-phoenix {
  font-family: "PT Sans Caption", "PT Sans", sans-serif;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  .nav-inline .nav-link {
    margin: 0;
  }
  .nav-inline .nav-link + .nav-link {
    margin-left: 0.5rem;
  }
  .nav-link {
    padding: 0.2rem;
    margin: 0 0.5rem;
    border-bottom: 3px solid transparent;
    transition: all 0.3s ease 0s;
    &:hover {
      border-bottom-color: $lightGreen;
    }
    &.active {
      font-weight: bold;
      color: #000;
      border-bottom-color: $redHighlight;
    }
  }
}

blockquote {
  padding-left: 2rem;
  color: $darkGreen;
  .attribution {
    text-align: right;
    font-style: italic;
  }
}

blockquote.testimonial {
  font-style: italic;
  color: $redHighlight;
  quotes: "\201C""\201D""\2018""\2019";
  .open-quote::before,
  .close-quote::after {
    font-style: normal;
    color: $lightGreen;
    font-size: 3rem;
    line-height: 0rem;
    vertical-align: -1.5rem;
  }
  .open-quote::before {
    content: open-quote;
  }
  .close-quote::after {
    content: close-quote;
  }
  p {
    margin: 0;
  }
}

.product-image {
  background-color: #fff;
  border: $border-width solid $lightGreen;
  @include border-radius($card-border-radius);
  img {
    margin-left: auto;
    margin-right: auto;
    @include img-fluid();
  }
}

.product-description {
  text-align: justify;
}

.product-info {
  font-family: "PT Sans", sans-serif;
  border-color: $lightGreen;
  border-style: solid;
  border-width: $border-width 0;
  padding-top: 1rem;
  margin: 1rem 0;
}

.product-info dl {
  dd.main {
    font-weight: bold;
  }
  @include media-breakpoint-up(sm) {
    @include make-row();
    @include clearfix;
    dt {
      clear: both;
      text-align: right;
      @include make-col-ready();
      @include make-col(3);
    }
    dd {
      @include make-col-ready();
      @include make-col(9);
    }
    dd + dd {
      @include make-col-offset(3);
    }
  }
}

.product-description h2,
.content h2 {
  border-bottom: 3px solid $redHighlight;
  font-family: "Alegreya SC", serif;
  margin-top: 0;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
}

.font-size-2x {
  font-size: 2em;
}

a.link:link {
  text-decoration: underline;
}

/*
@import url(https://fonts.googleapis.com/css?family=Alegreya:400,400italic,700,700italic);


h1,
h2,
h3,
.lead
{
    font-family: 'PT Serif';
}

.info {
    font-family: 'PT Sans Caption', sans-serif;
}

.msg
{
    font-family: 'PT Sans Caption', sans-serif;
    font-size: 11px;
    border: 1px solid #CC9;
    color: #F00;
    padding: 3px;
    text-align: center;
}

.footnote
{
    font-family: 'PT Sans Caption', sans-serif;
    font-size: 11px;
    color: #663;
}

.attribution
{
    text-align: right;
    font-style: italic;
}
*/

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.lead {
  @include font-size(1.25rem);
}

.alert-sitewide {
  @include alert-variant($washedGreen, $redHighlight, $redHighlight);
}

.text-balance {
  text-wrap: balance;
}
